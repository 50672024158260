const Portfolio = () => {
    return <>

        {/* Portfolio lightbox*/}
        <div className="lightbox-wrapper" id="portfolio" data-simplebar>
            <div className="container">
                {/* <div className="lightbox-close">
                    <div className="close-btn" data-modal-close><span className="btn-line" /></div>
                </div> */}
                <div className="row">
                    <div className="col-12">
                        <div className="lightbox-content">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section-heading page-heading">
                                        <p className="section-description">Showcasing some of my best work</p>
                                        <h2 className="section-title">Portfolio</h2>
                                        <div className="animated-bar" />
                                    </div>
                                </div>
                            </div>
                            {/* Portfolio section*/}
                            <div className="portfolio-section single-section">
                                <div className="row">
                                    {/* Filter nav*/}
                                    <div className="col-12">
                                        <ul className="list-inline filter-control" role="group" aria-label="Filter Control">
                                            <li className="list-inline-item tab-active" data-filter="*">All</li>
                                            <li className="list-inline-item" data-filter=".brand">Brand</li>
                                            <li className="list-inline-item" data-filter=".design">Design</li>
                                            <li className="list-inline-item" data-filter=".photos">Photos</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Thumbnail list*/}
                                <div className="portfolio-grid row">
                                    {/* Single item*/}
                                    <div className="single-item col-6 col-lg-4 design"><a className="portfolio-item" href="img/item-1.jpg" data-lightbox>
                                        <div className="portfolio-wrapper"><img className="img-fluid" alt="Item" src="img/item-1.jpg" />
                                            <div className="item-content">
                                                <h6 className="content-title">Label Tag Mockup</h6><span className="content-more">More Info</span>
                                            </div>
                                        </div>
                                    </a></div>
                                    {/* Single item*/}
                                    <div className="single-item col-6 col-lg-4 photos"><a className="portfolio-item" href="img/item-2.jpg" data-lightbox>
                                        <div className="portfolio-wrapper"><img className="img-fluid" alt="Item" src="img/item-2.jpg" />
                                            <div className="item-content">
                                                <h6 className="content-title">3D Bag Mockup</h6><span className="content-more">More Info</span>
                                            </div>
                                        </div>
                                    </a></div>
                                    {/* Single item*/}
                                    <div className="single-item col-6 col-lg-4 design"><a className="portfolio-item" href="img/item-3.jpg" data-lightbox>
                                        <div className="portfolio-wrapper"><img className="img-fluid" alt="Item" src="img/item-3.jpg" />
                                            <div className="item-content">
                                                <h6 className="content-title">Modern Bag Design</h6><span className="content-more">More Info</span>
                                            </div>
                                        </div>
                                    </a></div>
                                    {/* Single item*/}
                                    <div className="single-item col-6 col-lg-4 brand"><a className="portfolio-item" href="img/item-4.jpg" data-lightbox>
                                        <div className="portfolio-wrapper"><img className="img-fluid" alt="Item" src="img/item-4.jpg" />
                                            <div className="item-content">
                                                <h6 className="content-title">Coffee Cup Design</h6><span className="content-more">More Info</span>
                                            </div>
                                        </div>
                                    </a></div>
                                    {/* Single item*/}
                                    <div className="single-item col-6 col-lg-4 brand"><a className="portfolio-item" href="img/item-5.jpg" data-lightbox>
                                        <div className="portfolio-wrapper"><img className="img-fluid" alt="Item" src="img/item-5.jpg" />
                                            <div className="item-content">
                                                <h6 className="content-title">T-Shirt Design</h6><span className="content-more">More Info</span>
                                            </div>
                                        </div>
                                    </a></div>
                                    {/* Single item*/}
                                    <div className="single-item col-6 col-lg-4 photos"><a className="portfolio-item" href="img/item-6.jpg" data-lightbox>
                                        <div className="portfolio-wrapper"><img className="img-fluid" alt="Item" src="img/item-6.jpg" />
                                            <div className="item-content">
                                                <h6 className="content-title">Packaging Box Mockup</h6><span className="content-more">More Info</span>
                                            </div>
                                        </div>
                                    </a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Portfolio;
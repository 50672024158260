const Resume = () => {
  const professionalSkillsFirst = [
    {
      id: 1,
      name: "Web Design",
      value: 50,
    },
    {
      id: 2,
      name: "Logo Design",
      value: 50,
    },
    {
      id: 3,
      name: "Graphic Design",
      value: 50,
    },
    {
      id: 4,
      name: "Laravel",
      value: 65,
    },
    {
      id: 5,
      name: "Bootstrap",
      value: 75,
    },
    {
      id: 6,
      name: "JavaScript",
      value: 75,
    },
    {
      id: 7,
      name: "PHP",
      value: 60,
    },
    {
      id: 7,
      name: "Typescript",
      value: 20,
    },
  ];
  const professionalSkillsSecond = [
    {
      id: 1,
      name: "Python",
      value: 25,
    },
    {
      id: 2,
      name: "Umbraco",
      value: 40,
    },
    {
      id: 3,
      name: "HTML / CSS",
      value: 99,
    },
    {
      id: 4,
      name: "C#",
      value: 25,
    },
    {
      id: 5,
      name: "ReactJS",
      value: 40,
    },
    {
      id: 6,
      name: "React-native",
      value: 25,
    },
    {
      id: 7,
      name: "Flutter",
      value: 20,
    },
  ];

  const renderListOfProfessionalSkills = (skills) => {
    return skills.map((item) => (
      <div id={item.id} className="single-skill" data-percentage={item.value}>
        {/* <div className="skill-info">
          <span className="skill-name">{item.name}</span>
          <span className="skill-percentage" />
        </div> */}
        <div id={item.id} className="single-skill">
          <div className="skill-info">
            <span className="skill-name">{item.name}</span>
            <span style={{ color: "#009e66", fontSize: "20px" }}>
              <i className="icon ion-md-checkmark-circle-outline" />
            </span>
          </div>
        </div>
        {/* <div className="progress skill-progress">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </div> */}
      </div>
    ));
  };
  return (
    <>
      <div className="container page-top-margin-medium page-bottom-margin">
        {/* <div className="lightbox-close">
                    <div className="close-btn" data-modal-close><span className="btn-line" /></div>
                </div> */}
        <div className="row">
          <div className="col-12">
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="section-heading page-heading">
                    {/* <p className="section-description">Check out my Resume</p> */}
                    <h2 className="section-title text-center p-4">Resume</h2>
                    <div className="animated-bar" />
                  </div>
                </div>
              </div>
              {/* Resume section*/}
              <div className="resume-section single-section">
                <div className="row">
                  {/* Education part*/}
                  {/* <div className="col-12 col-md-6">
                                        <div className="col-block education">
                                            <h3 className="col-title">Education</h3>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">Bachelor of Science</h5>
                                                </a>
                                                <span className="item-details">Hogeschool Utrecht / 2019 - 2024</span>
                                                <p className="item-description">In 2019 they started with a new program: Open-ICT. Open-ICT gives you a lot of freedom to decide what you want to learn. However, you must be able to handle this freedom. Open-ICT therefore fits particularly well with me because I like to study independently, want to work together with others, have a high ambition and self-discipline.</p>
                                            </div>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">Software Developer</h5>
                                                </a>
                                                <span className="item-details">Natin - MBO / 2010 - 2014</span>
                                                <p className="item-description">At NATIN-MBO I learned the fundamentals of programming, designing systems using the Unified Modeling Language, creating databases and developing in C++ and Java.</p>
                                            </div>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">A+ PC technician</h5>
                                                </a>
                                                <span className="item-details">Hogeschool Utrecht / 2011 - 2012</span>
                                                <p className="item-description">This was a training of six months where I learned to install and configure operating systems, assemble hardware of a computer and laptop, troubleshoot and fix software and hardware problems.</p>
                                            </div>
                                            <div className="resume-item"><span className="item-arrow" />
                                                <a href="#" target="_blank" rel="noreferrer" >
                                                    <h5 className="item-title">Electrical Engineering</h5>
                                                </a>
                                                <span className="item-details">Suriname Technical School 2 / 2006 - 2010</span>
                                                <p className="item-description">STS stands for Suriname Technical School, a school where I learned the basics of electrical engineering.</p>
                                            </div>
                                        </div>
                                    </div> */}
                  {/* Experience part*/}
                  <div className="col-12 col-md-6">
                    <div className="col-block experience">
                      <h3 className="col-title">Experience NL</h3>

                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Software Developer / Internship / Part time
                        </h5>
                        <span className="item-details">
                          Experience Data / Experience Fruit Quality / Nov 2022
                          - Jul 2024
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As a software developer, I
                          work closely with teams to bring ideas to life through
                          dynamic prototypes that are both interactive and
                          user-friendly. One recent project involved developing
                          a web-based platform for a client aiming to streamline
                          their business operations. The platform required
                          features like user authentication, session management,
                          and data-sharing capabilities across multiple users
                          within a collaborative workspace.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My role involved not only
                          conceptualizing and designing prototypes but also
                          ensuring they were aligned with the overall platform
                          and project requirements. Additionally, I translated
                          business rules from clients into code logic, ensuring
                          they were reusable for other clients with matching
                          configurations.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I took ownership of
                          maintaining project designs in Figma, collaborating
                          with stakeholders to make adjustments as needed, and
                          creating dynamic, functional prototypes that can be
                          easily integrated into the platform. I also ensure
                          that business logic is built with flexibility,
                          allowing for efficient reuse across different client
                          projects.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: By focusing on clear
                          communication, attention to detail, and adaptability,
                          I consistently delivered prototypes that improved the
                          user experience, align with team goals, and streamline
                          the transition from design to implementation. The
                          reusable business logic has also helped to reduce
                          development time for similar client configurations.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Junior Frontend Developer
                        </h5>
                        <span className="item-details">
                          Valsecco / Feb 2021 - Feb 2022
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: At Valsecco, the web
                          application’s UI was outdated and no longer met the
                          needs of its users, leading to a decline in user
                          engagement. The platform had been built several years
                          prior and had not kept pace with modern design trends
                          or user experience standards, making it difficult to
                          navigate and maintain.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My responsibility was to
                          implement Microsoft Fluent UI using React to overhaul
                          the front-end design.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I collaborated with design
                          and development teams to plan and implement the new
                          UI, focusing on improving user experience. I ensured
                          smooth communication and problem-solving during the
                          transition, addressing challenges with the older
                          codebase.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The redesign enhanced the
                          application’s look and usability, creating a more
                          intuitive interface and aligning the platform with
                          modern UI standards.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Frontend developer / Internship
                        </h5>
                        <span className="item-details">
                          Afas software / Sep 2020 - Feb 2021
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: At AFAS Software, the
                          content team was facing challenges retrieving
                          real-time data from their main platform. Previously,
                          they relied on Excel sheets to populate their test
                          environments, which was inefficient and prone to
                          errors. The process was time-consuming and often led
                          to outdated or inconsistent data being used, affecting
                          the accuracy of their testing. The need for a more
                          reliable, automated solution became urgent as the
                          team’s workload increased.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My responsibility was to
                          develop the front-end UI/UX for a new software tool
                          that could retrieve real-time data from the AFAS
                          software package, solving the inefficiencies caused by
                          manual data entry.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I collaborated with the
                          content and development teams to design a
                          user-friendly interface, focusing on clear navigation
                          and seamless user interaction. I prioritized
                          usability, ensuring the tool could easily replace the
                          old Excel-based process while meeting the technical
                          requirements of the platform.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The tool’s intuitive
                          interface eliminated the need for manual Excel
                          entries, improving the content team’s productivity and
                          accuracy in populating test environments.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Software Developer</h5>
                        <span className="item-details">
                          Vergelijkgroep / Jun 2020 - Sep 2020
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As a software developer at
                          Vergelijkgroep, I was tasked with enhancing the
                          marketing department’s tools by developing new
                          features. The existing tools were outdated and lacked
                          the flexibility needed to keep up with the marketing
                          team's evolving strategies. The department was looking
                          for ways to automate processes and improve the
                          efficiency of their campaigns.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My responsibility was to
                          implement these features using the Yii framework,
                          which was new to me and presented a learning
                          challenge.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I quickly mastered the Yii
                          framework, working closely with the marketing and
                          development teams to ensure the features aligned with
                          their needs and integrated smoothly with existing
                          systems.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The new features streamlined
                          the marketing tools, improving functionality and
                          supporting the department's goals.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Freelance Web Developer</h5>
                        <span className="item-details">
                          Freelancer / Nov 2019 - April 2020
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As a freelance developer,
                          I identified a growing demand for robust and efficient
                          webshops, especially for small businesses looking to
                          expand their online presence. Many clients struggled
                          with outdated or insufficient e-commerce solutions,
                          which affected their ability to compete in the digital
                          market.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My role was to create and
                          implement WordPress websites integrated with the
                          WooCommerce plugin, focusing on delivering a seamless
                          online shopping experience.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I added comprehensive content
                          to each online store, inserted data in bulk using a
                          creative yet complex method, and set up Stripe as the
                          payment method to ensure secure transactions.
                          Additionally, I managed domain registrations and
                          selected appropriate hosting services to optimize the
                          performance and reliability of the webshops.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The webshops I developed
                          offered clients secure, user-friendly online stores
                          that boosted sales and customer satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="col-block experience">
                      <h3 className="col-title">Experience SR</h3>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Software Developer</h5>
                        <span className="item-details">
                          ADEPT NV / Mar 2018 - Jun 2019
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As a software developer at
                          ADEPT, the company was facing the dual challenge of
                          needing to develop new software programs to keep pace
                          with business growth while also improving the
                          performance of existing applications. Some of the
                          legacy systems were struggling under the increased
                          load, impacting overall efficiency and user
                          experience.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My responsibility was to
                          develop and implement new software solutions while
                          also focusing on the maintenance and optimization of
                          current applications.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I collaborated with
                          cross-functional teams to gather requirements for new
                          software, ensuring that the solutions aligned with
                          user needs and business goals. For existing software,
                          I conducted performance assessments, identifying areas
                          for improvement and implementing enhancements. This
                          involved optimizing code, debugging issues, and
                          refining user interfaces to enhance functionality.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The new software programs
                          improved operations and effectively met user needs,
                          while performance improvements for existing
                          applications led to increased efficiency and user
                          satisfaction.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          HACKOMATION INTERNET OF THINGS PROTOTYPING CHALLENGE
                        </h5>
                        <span className="item-details">
                          IT-Core / 2017 - 2018
                        </span>
                        <p className="item-description">
                          At this event a group of 3 – 5 members should build a
                          working IOT hack/prototype within the time-frame of 3
                          months. The prototypes were judged by expert panel on
                          technical aspects/complexity, user-friendliness,
                          features and commercial or social value. I
                          participated with 3 other team members for this event.
                          All members had their specific role. With some
                          sleepless nights and headaches we ended up taking the
                          second place by building a pest control with a
                          monitoring system and automatic bug detection.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">Junior Data Engineer</h5>
                        <span className="item-details">
                          Staatsziekenfonds Suriname / Mar 2017 - Mar 2018
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As a Data Engineer at SZF,
                          the company was dealing with an ever-increasing volume
                          of data from multiple sources, which made data
                          management and access complex and inefficient. The
                          existing systems were outdated and struggled to
                          support the company's growing need for streamlined
                          data collection and analysis, limiting the ability of
                          data scientists to conduct timely and accurate
                          analyses.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My role involved developing and
                          maintaining databases and large-scale systems that
                          handle vast amounts of data.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I focused on creating and
                          testing processes to ensure smooth data flow in and
                          out of these data pools. This setup allowed data
                          scientists to easily access the relevant data sets
                          they needed for their analyses.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The systems I developed
                          provided reliable and organized data access, enabling
                          data scientists to conduct their analyses more
                          efficiently and effectively.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Technical Application Administrator
                        </h5>
                        <span className="item-details">
                          Staatsziekenfonds Suriname / Oct 2015 - Feb 2017
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As an Application
                          Administrator at SZF, I managed a variety of
                          applications crucial for supporting the organization's
                          daily operations. The company was transitioning to
                          more digital and automated solutions, making it
                          essential to ensure that all applications were
                          up-to-date, well-integrated, and aligned with the
                          company’s evolving needs.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My responsibilities included
                          installing, configuring, and overseeing both small and
                          large applications, ensuring they functioned smoothly.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I developed an API for the
                          mobile application, which enabled clients to easily
                          access their insurance information. This involved
                          collaborating with the development team to ensure the
                          API met user requirements and integrated seamlessly
                          with the existing systems.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: The applications I managed
                          improved operational efficiency, while the API
                          enhanced client engagement by providing easy access to
                          important insurance information.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          Co-founder & Web Developer
                        </h5>
                        <span className="item-details">
                          LAZRLAB / Oct 2013 - May 2015
                        </span>
                        <p className="item-description">
                          <strong>Situation</strong>: As one of the co-founders
                          of LAZRLAB, I embarked on my journey in software
                          development, taking on various responsibilities to
                          build the company from the ground up. The company was
                          focused on providing web solutions for small
                          businesses, and we aimed to deliver high-quality,
                          customized services to help clients establish a strong
                          online presence.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Task</strong>: My role included designing
                          websites, creating data models, maintaining websites,
                          and developing web applications, all of which were
                          foundational to our offerings.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Action</strong>: I learned and implemented
                          everything from scratch, diving into web design and
                          development processes. I created visually appealing
                          website layouts and user interfaces, ensuring they
                          were not only functional but also aligned with user
                          experience principles. Additionally, I developed data
                          models to organize and manage information effectively,
                          which was crucial for our web applications. I took
                          charge of ongoing website maintenance, troubleshooting
                          issues, and implementing updates to enhance
                          performance and security. Collaborating closely with
                          team members, I ensured our projects met client
                          specifications and adhered to best practices in web
                          development.
                        </p>
                        <p className="item-description pt-2">
                          <strong>Result</strong>: My contributions laid the
                          groundwork for LAZRLAB, establishing a strong
                          foundation in software development and equipping me
                          with essential skills that continue to benefit my
                          career.
                        </p>
                      </div>
                      <div className="resume-item">
                        <span className="item-arrow" />
                        <h5 className="item-title">
                          CANTO/Ericsson 3rd Annual i-Create e-Content
                          Competition
                        </h5>
                        <span className="item-details">
                          CANTO / 2012 - 2013
                        </span>
                        <p className="item-description">
                          In the year 2013 a friend and I won the first place in
                          the CANTO/Ericsson’s third i-Create e-Content Mobile
                          Apps Competition. This annual competition rewards the
                          most creative and innovative mobile application (apps)
                          developers in the Caribbean. The competition was
                          staged in all CANTO member countries.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Skills section*/}
              <div className="skills-section single-section page-top-margin-medium">
                <div className="row">
                  <div className="col-12">
                    <div className="section-heading">
                      {/* <p className="section-description">My level of knowledge in some tools</p> */}
                      <h2 className="section-title">Professional Skills</h2>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-md-6">
                    {renderListOfProfessionalSkills(professionalSkillsFirst)}
                  </div>
                  <div className="col-12 col-md-6">
                    {renderListOfProfessionalSkills(professionalSkillsSecond)}
                  </div>
                </div>
              </div>
              {/* Video section*/}
              {/* <div className="video-section single-section">
                                <div className="row align-items-center">
                                    <div className="col-12 col-lg-6 content-part">
                                        <h3 className="video-title">Take a tour of my office</h3>
                                        <p className="video-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia cum quasi assumenda culpa praesentium consectetur voluptatibus expedita. Voluptatem tempore, aspernatur rem facilis, distinctio nemo! Odio velit, nemo dolorem voluptas!</p>
                                        <p className="video-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium qui aspernatur unde mollitia, in laborum.</p>
                                    </div>
                                    <div className="col-12 col-lg-6 video-part"><a className="media-lightbox" href="https://www.youtube.com/watch?v=doteMqP6eSc" data-lightbox>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <div className="embed-responsive-item element-cover-bg"><span className="play-wrapper"><i className="icon ion-md-play" /></span></div>
                                        </div>
                                    </a></div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Resume;

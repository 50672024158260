
import About from "./About";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
import Blogs from "./Blogs";
import Contact from "./Contact";
const Home = () => {

  const lifePerks = [
    {
        id: 1,
        name: "Adventurous"
    },
    {
        id: 2,
        name: "Adaptable"
    },
    {
        id: 3,
        name: "Cheerful"
    },
    {
        id: 4,
        name: "Courageous"
    },
    {
        id: 5,
        name: "Enthusiastic"
    },
    {
        id: 6,
        name: "Flexible"
    },
    {
        id: 7,
        name: "Problemn solver"
    },
    {
        id: 8,
        name: "just Awesome."
    },
]
// const [bgVideoState, setbgVideoState] = useState(true);
// const handleBgStateClick = () => {
//   setbgVideoState(!bgVideoState);
//   console.log(bgVideoState)
// };

// const bgVideos = [
//   {
//       id: 1,
//       value: "https://www.youtube.com/watch?v=pSHLgwnmPHA"
//   },
//   {
//       id: 2,
//       value: "https://www.youtube.com/watch?v=vAwsatsmO4s"
//   },
// ]

const renderListOfPerks = (perks) => {
  return perks.map((item) => <b id={item.id}>{item.name}</b>)
}

  return (
    <>
      <section class="home-area element-cover-bg" id="home" style={{ backgroundImage: 'url(img/cssBg.jpg)' }}>
      {/* <div class="player" id="homeVideo" data-property="{videoURL:'https://www.youtube.com/watch?v=vAwsatsmO4s',containment:'#home',autoPlay:true, mute:true, showControls:false, stopMovieOnBlur:false, showYTLogo: false}"></div> */}
      {/* <section className="home-area element-cover-bg" id="home" style={{ backgroundImage: 'url(img/home.jpg)' }}> */}
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-12 col-lg-8 home-content text-center">
              <h1 className="home-name">.C <span>S</span> S.</h1>
              <h4 className="cd-headline clip home-headline">clint sarijan siadis</h4>
             {/* <h4 className="cd-headline clip home-headline">Is <span className="cd-words-wrapper single-headline"><b className="is-visible">...</b>{renderListOfPerks(lifePerks)}</span></h4> */}
            </div>
          </div>
        </div>
        <div className="fixed-wrapper">
          {/* Languages list*/}
          <div className="fixed-block block-left">
            <ul className="list-unstyled">
              {/* <li><a href="#"><span onClick={handleBgStateClick} className="single-language text-white">Turn {bgVideoState ? "Off" : "On"}</span></a></li> */}
              {/* <li><a href="#0"><span className="single-language">SR</span></a></li> */}
            </ul>
          </div>
          {/* Social media icons*/}
          <div className="fixed-block block-right">
            <ul className="list-unstyled social-icons">
              <li><a href="https://www.linkedin.com/in/clint-siadis-1413a473/" target="_blank" rel="noreferrer" ><i className="icon ion-logo-linkedin" /></a></li>
              <li><a href="https://github.com/clint1302" target="_blank" rel="noreferrer" ><i className="icon ion-logo-github" /></a></li>
            </ul>
          </div>
        </div>
      </section>
      <About />
      <Resume />
      <Portfolio />
      <Blogs />
      <Contact />
    </>
  );
};

export default Home;
const Blogs = () => {
  return <>
    {/* Blog lightbox*/}
    <div className="lightbox-wrapper" id="blog" data-simplebar>
      <div className="container">
        {/* <div className="lightbox-close">
          <div className="close-btn" data-modal-close><span className="btn-line" /></div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <div className="lightbox-content">
              <div className="row">
                <div className="col-12">
                  <div className="section-heading page-heading">
                    <p className="section-description">Check out my latest blog posts</p>
                    <h2 className="section-title">My Blog</h2>
                    <div className="animated-bar" />
                  </div>
                </div>
              </div>
              {/* Blog section*/}
              <div className="blog-section single-section">
                <div className="row justify-content-center">
                  {/* Single post*/}
                  <div className="col-12 col-sm-8 col-lg-4">
                    <div className="card single-post"><a className="post-img" href="#0"><img className="card-img-top" src="img/post-1.jpg" alt="Blog post" /><span className="content-date">11 Dec, 20</span></a>
                      <div className="card-body post-content"><a href="#0">
                        <h5 className="card-title content-title">Top tools for Photographers</h5>
                      </a>
                        <p className="card-text content-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo quam, eum alias id, repellendus magni, quas.</p>
                      </div>
                    </div>
                  </div>
                  {/* Single post*/}
                  <div className="col-12 col-sm-8 col-lg-4">
                    <div className="card single-post"><a className="post-img" href="#0"><img className="card-img-top" src="img/post-2.jpg" alt="Blog post" /><span className="content-date">12 Aug, 20</span></a>
                      <div className="card-body post-content"><a href="#0">
                        <h5 className="card-title content-title">Take a tour of my office</h5>
                      </a>
                        <p className="card-text content-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo quam, eum alias id, repellendus magni, quas.</p>
                      </div>
                    </div>
                  </div>
                  {/* Single post*/}
                  <div className="col-12 col-sm-8 col-lg-4">
                    <div className="card single-post"><a className="post-img" href="#0"><img className="card-img-top" src="img/post-3.jpg" alt="Blog post" /><span className="content-date">4 Feb, 20</span></a>
                      <div className="card-body post-content"><a href="#0">
                        <h5 className="card-title content-title">How i became a Web Designer</h5>
                      </a>
                        <p className="card-text content-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo quam, eum alias id, repellendus magni, quas.</p>
                      </div>
                    </div>
                  </div>{/* Single post*/}
                  <div className="col-12 col-sm-8 col-lg-4">
                    <div className="card single-post"><a className="post-img" href="#0"><img className="card-img-top" src="img/post-4.jpg" alt="Blog post" /><span className="content-date">11 Dec, 19</span></a>
                      <div className="card-body post-content"><a href="#0">
                        <h5 className="card-title content-title">How to improve work performance</h5>
                      </a>
                        <p className="card-text content-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo quam, eum alias id, repellendus magni, quas.</p>
                      </div>
                    </div>
                  </div>
                  {/* Single post*/}
                  <div className="col-12 col-sm-8 col-lg-4">
                    <div className="card single-post"><a className="post-img" href="#0"><img className="card-img-top" src="img/post-5.jpg" alt="Blog post" /><span className="content-date">15 Nov, 19</span></a>
                      <div className="card-body post-content"><a href="#0">
                        <h5 className="card-title content-title">How to work from home</h5>
                      </a>
                        <p className="card-text content-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo quam, eum alias id, repellendus magni, quas.</p>
                      </div>
                    </div>
                  </div>
                  {/* Single post*/}
                  <div className="col-12 col-sm-8 col-lg-4">
                    <div className="card single-post"><a className="post-img" href="#0"><img className="card-img-top" src="img/post-6.jpg" alt="Blog post" /><span className="content-date">8 Aug, 19</span></a>
                      <div className="card-body post-content"><a href="#0">
                        <h5 className="card-title content-title">How to enjoy your business trip</h5>
                      </a>
                        <p className="card-text content-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, porro rem quod illo quam, eum alias id, repellendus magni, quas.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default Blogs;
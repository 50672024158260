import { Link } from "react-router-dom";
import cvSiadisPdf from "./files/cv_siadis.pdf";
const Navbar = () => {
  return (
    <nav className="navbar-expand-md navbar fixed-top" id="navbar">
      <a className="navbar-brand" data-scroll href="#home-area">
        {/* Navbar Logo*/}
        <a
          className="btn content-download button-main button-scheme"
          href={cvSiadisPdf}
          target="_blank"
          rel="noreferrer"
          role="button"
        >
          Download CV
        </a>
        {/* <img className="img-fluid" src="img/img-kitzu-logo.png" alt="Logo" />*/}
      </a>
      <span
        className="navbar-menu ml-auto"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        role="button"
      >
        <span className="btn-line" />
      </span>
      <div
        className="collapse navbar-collapse order-1 order-lg-0"
        id="navbarSupportedContent"
      >
        {/* Navbar menu*/}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/resume">
              Resume
            </Link>
          </li>
          {/* <li className="nav-item">
                        <Link className="nav-link" to="/blogs">Blogs</Link>
                    </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
          {/* <li className="nav-item"><a className="nav-link" href="#about">About</a></li>
                    <li className="nav-item"><a className="nav-link" href="#resume">Resume</a></li> */}
          {/* <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li> */}
          {/* <li className="nav-item"><a className="nav-link" href="#portfolio">Portfolio</a></li> */}
          {/* <li className="nav-item"><a className="nav-link" href="#blog">Blogs</a></li> */}
          {/* <li className="nav-item">
                        <Link className="nav-link" to="/resume">Resume</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/contact">Portfolio</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/contact">Blog</Link>
                    </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

const Contact = () => {
  return (
    <>
      <div className="container page-top-margin-large page-bottom-margin">
        <div className="row">
          <div className="col-12">
            <div className="section-heading page-heading text-center">
              <h2 className="section-title">Get in Touch</h2>
              <div className="animated-bar" />
            </div>
          </div>
        </div>
        {/* Contact section*/}
        <div className="contact-section single-section">
          <div className="row">
            {/* Contact form*/}
            <div className="col-12 col-lg-7">
              <img
                className="img-fluid"
                src="img/contactPageMap.png"
                alt="contact page map"
              />
            </div>
            {/* Contact info*/}
            <div className="col-12 col-lg-5">
              <div className="contact-info">
                <h4 className="content-title">Contact Info</h4>
                <p className="info-description">
                  Feel free to contact me anytime
                </p>
                <ul className="list-unstyled list-info">
                  <li>
                    <div className="media align-items-center">
                      <span className="info-icon">
                        <i className="icon ion-logo-ionic" />
                      </span>
                      <div className="media-body info-details">
                        <h6 className="info-type">Name</h6>
                        <span className="info-value">Clint Siadis</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media align-items-center">
                      <span className="info-icon">
                        <i className="icon ion-md-map" />
                      </span>
                      <div className="media-body info-details">
                        <h6 className="info-type">Location</h6>
                        <span className="info-value">
                          Utrecht, Netherlands.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media align-items-center">
                      <span className="info-icon">
                        <i className="icon ion-md-send" />
                      </span>
                      <div className="media-body info-details">
                        <h6 className="info-type">Email Me</h6>
                        <span className="info-value">
                          <a href="mailto:csiadis@gmail.com">
                            csiadis@gmail.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const Layout = () => {
  return (
    <>
      {/* <div>
        <div className="preloader">
          <div className="preloader-block">
            <div className="preloader-icon"><span className="loading-dot loading-dot-1" /><span className="loading-dot loading-dot-2" /><span className="loading-dot loading-dot-3" /></div>
          </div>
        </div>
        <div id="overlay-effect" />
      </div> */}
      <Navbar />
      <Outlet />
    </>
  );
};

export default Layout;
